import { Box, type SpacingAlias } from "@yahoo/uds";
import { I13nAnchor } from "@yahoo-commerce/i13n";
import HealthLogoSvg from "@/svgs/healthLogo.svg";

interface HealthLogoProps {
  spacingBottom?: SpacingAlias;
}

export const HealthLogo = ({ spacingBottom = "6" }: HealthLogoProps) => {
  return (
    <Box spacingBottom={spacingBottom}>
      <I13nAnchor
        href="https://health.yahoo.com/"
        aria-label="Yahoo Health"
        dataYlk={{
          elm: "logo",
          slk: "yahoo health",
        }}
      >
        <HealthLogoSvg height="25" />
      </I13nAnchor>
    </Box>
  );
};

export default HealthLogo;
